import moment from 'moment'

export default class DataUtils {
  constructor() { this.arrayAnos = [] }

  getArrayAnos(ano) {
    const dataFinal = moment().add(1, 'years')
    let data = moment(`${ano}-01-01`)
    this.arrayAnos = []
    while (data.format('YYYY') <= dataFinal.format('YYYY')) {
      this.arrayAnos.push(data.format('YYYY'))
      data = data.add(1, 'years')
    }
    return this.arrayAnos.reverse()
  }
}
